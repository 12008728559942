import { render, staticRenderFns } from "./Authorized.vue?vue&type=template&id=13485b0f"
import script from "./Authorized.vue?vue&type=script&lang=js"
export * from "./Authorized.vue?vue&type=script&lang=js"
import style0 from "@/shared/css/fonts.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@/shared/css/global-styles.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports