import { render, staticRenderFns } from "./ContentArea.vue?vue&type=template&id=2c188ea2&scoped=true"
import script from "./ContentArea.vue?vue&type=script&lang=js"
export * from "./ContentArea.vue?vue&type=script&lang=js"
import style0 from "./ContentArea.vue?vue&type=style&index=0&id=2c188ea2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c188ea2",
  null
  
)

export default component.exports